<template>
  <div v-if="course" class="forms-elements">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />

    <Widget v-if="course">
      <b-row>
        <b-col lg="6" md="6" sm="12">
          <legend>
            <strong>Basisdaten</strong>
          </legend>

          <b-form-group
            horizontal
            :label="$t('name')"
            label-for="name"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="course.name" type="text" :placeholder="$t('name')" />
          </b-form-group>
          <b-col lg="6" md="6" sm="12">
            <legend>
              <strong>Lernmodule auswählen</strong>
            </legend>
            <PickList
              :source="pickedSessionsFormatted.source"
              :target="pickedSessionsFormatted.target"
              :updateCallback="updateCourse"
              v-model="pickedSessions"
              @change="handlePickListChange"
              dataKey="id"
            >
              <template #sourceheader>
                Verfügbare Module
              </template>
              <template #targetheader>
                Zugeordnete Module
              </template>
              <template #item="slotProps">
                <div class="product-item">
                  <div class="product-list-detail">
                    <h5 class="mb-2">{{ slotProps.item.name }}</h5>
                  </div>
                </div>
              </template>
            </PickList>
          </b-col>
        </b-col>
        <b-col lg="6" md="6" sm="12"> </b-col>
      </b-row>
      <b-row>
        <b-col lg="12" md="12" sm="12">
          <ButtonBar
            :showPreviousButton="buttons.showPreviousButton"
            :showNextButton="buttons.showNextButton"
            :showDeleteButton="buttons.showDeleteButton"
            :model="course"
            modelEditRoute="CourseEditPage"
            modelListRoute="Courses"
            modelRouteParamName="courseNumber"
            :nextModelNumber="course.nextCourseNumber"
            :previousModelNumber="course.previousCourseNumber"
            :updateCallback="saveCourseSessions"
            :createCallback="createCourse"
            :deleteCallback="deleteCourse"
            :fetchCallback="fetchCourses"
          />
        </b-col>
      </b-row>
    </Widget>
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';
import Breadcrumbs from '@/components/Breadcrumbs';
import { mapGetters, mapActions } from 'vuex';
import Multiselect from 'vue-multiselect';
import ButtonBar from '@/components/ButtonBar.vue';
import PickList from 'primevue/picklist';

export default {
  name: 'CourseEditPage',
  components: {
    Widget,
    Breadcrumbs,
    // Multiselect,
    ButtonBar,
    PickList,
  },
  props: {
    courseNumber: {
      required: false,
      default: null,
    },
  },
  data() {
    return {
      number: null,
      filteredUsers: [],
      pickedSessions: [[], []],
    };
  },
  computed: {
    ...mapGetters({
      getCourse: 'getCourse',
      getCourseByNumber: 'getCourseByNumber',
      getEnumValues: 'getEnumValues',
      getUsers: 'getUsers',
      allSessions: 'getSessions',
    }),
    buttons() {
      return {
        showDeleteButton: this.course && this.course.number !== '_new' ? true : false,
      };
    },
    course() {
      return this.getCourseByNumber(this.number);
    },
    breadcrumbs() {
      return [
        { name: 'Home', route: { name: 'home' } },
        { name: this.$t('course'), route: { name: 'Courses' } },
        { name: this.course.number !== '_new' ? this.$t('edit') : this.$t('new') },
      ];
    },
    sessionContents() {
      return this.session ? this.session.contents : [];
    },
    pickedSessionsFormatted() {
      if (!this.allSessions || !this.allSessions.length || !this.course || !this.course.sessions) {
        return { source: [], target: [] };
      }

      const source = this.allSessions.filter(
        (session) => !this.course.sessions.includes(session.id)
      );
      const target = this.course.sessions
        .map((id) => this.allSessions.find((session) => session.id === id))
        .filter((session) => session);

      return { source, target };
    },
  },
  methods: {
    ...mapActions({
      fetchCourses: 'fetchCourses',
      fetchCourseByNumber: 'fetchCourseByNumber',
      updateCourse: 'updateCourse',
      createCourse: 'createCourse',
      deleteCourse: 'deleteCourse',
      fetchEnumValues: 'fetchEnumValues',
      initCourse: 'initCourse',
      fetchSessions: 'fetchSessions',
    }),
    handlePickListChange(event) {
      if (!event || !event.value || !Array.isArray(event.value) || event.value.length < 2) {
        return;
      }
      this.pickedSessions = event.value;
      this.saveCourseSessions();
    },
    saveCourseSessions() {
      if (!this.pickedSessions || this.pickedSessions.length < 2) {
        return;
      }
      const sessionIds = this.pickedSessions[1].map((item) => item.id || item);
      this.$set(this.course, 'sessions', sessionIds);
      this.updateCourse(this.course);
    },
    initializePickListData() {
      if (!this.course) {
        console.warn('Course not found.');
        return;
      }
      const courseSessionsIds = this.course.sessions.map((item) => item.session || item);

      this.pickedSessions = [
        this.allSessions.filter((session) => !courseSessionsIds.includes(session.id)),
        this.allSessions.filter((session) => courseSessionsIds.includes(session.id)),
      ];
    },
    /**
     *
     */
    // async save(course) {
    //   if (course.number === '_new') {
    //     await this.createCourse(course);
    //   } else {
    //     await this.updateCourse(course);
    //   }
    // },
  },

  watch: {
    pickedSessions(newVal, oldVal) {},
    '$route.params.sessionNumber': {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.number = parseInt(newVal, 10);
          this.setup();
        }
      },
    },
  },
  async mounted() {
    if (this.courseNumber) {
      this.number = this.courseNumber;
      await this.fetchCourseByNumber(this.number);
      await this.fetchSessions();
      if (this.course && this.allSessions && this.allSessions.length) {
        this.initializePickListData();
      }
    } else {
      this.initCourse();
      this.number = '_new';
    }
  },
};
</script>

<style scoped lang="scss"></style>
